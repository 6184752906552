import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap'
import { Component } from 'react'
import '../../global_styles/NavBar.css'

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      aboutActive: false,
    }
    const about_paths = ['/mission', '/curriculum', '/faq']
    for (var i = 0; i < about_paths.length; i++) {
      if (about_paths[i] == this.props.location.pathname) {
        this.state = {
          aboutActive: true,
        }
      }
    }
  }
  render() {
    return (
      <Navbar collapseOnSelect expand='md' variant='dark' className='mb-3'>
        <Container fluid>
          <Navbar.Brand href='/'>
            <b>Eager Minds</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'></Nav>
            <Nav activeKey={this.props.location.pathname}>
              <Nav.Link href='/'>Home</Nav.Link>
              <NavDropdown active={this.state.aboutActive} title='About' id='basic-nav-dropdown'>
                <NavDropdown.Item href='/curriculum'>Curriculum</NavDropdown.Item>
                <NavDropdown.Item href='/mission'>Mission</NavDropdown.Item>
                {/* <NavDropdown.Item href='/our_story'>Our Story</NavDropdown.Item> */}

                {/* <NavDropdown.Item href='/faq'>FAQ</NavDropdown.Item> */}
              </NavDropdown>
              {/* <Nav.Link href='/enroll'>Enroll</Nav.Link> */}
              <Nav.Link href='/contact'>Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

// const Navigation = (props) => (
//   <Navbar collapseOnSelect expand='md' variant='dark' className='mb-3'>
//     <Container fluid>
//       <Navbar.Brand href='/'>
//         <b>Eager Minds</b>
//       </Navbar.Brand>
//       <Navbar.Toggle aria-controls='responsive-navbar-nav' />
//       <Navbar.Collapse id='responsive-navbar-nav'>
//         <Nav className='me-auto'></Nav>
//         <Nav activeKey={props.location.pathname}>
//           <Nav.Link href='/'>Home</Nav.Link>
//           <NavDropdown title='About' id='basic-nav-dropdown'>
//             <NavDropdown.Item href='/mission'>Mission</NavDropdown.Item>
//             <NavDropdown.Item href='/curriculum'>Curriculum</NavDropdown.Item>
//             <NavDropdown.Item href='/faq'>FAQ</NavDropdown.Item>
//           </NavDropdown>
//           <Nav.Link href='/enroll'>Enroll</Nav.Link>
//           <Nav.Link href='/contact'>Contact Us</Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//     </Container>
//   </Navbar>
// )

export default Navigation
