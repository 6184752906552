import { Tabs, Tab } from 'react-bootstrap'
import { Container, Row, Col } from 'react-bootstrap'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import styles from './About.module.css'
import React, { Component } from 'react'
import MissionImage from './writing.png'
import BlackPencils from './black_pencils.png'
import OrangePencils from './orange_pencils.png'
import GreenPencils from './green_pencils.png'
import YellowPencils from './yellow_pencils.png'
import BluePencil from './blue_pencil.png'
import GreenPencil from './green_pencil.png'
import GettingWork from './getting_work.png'
import RedPencil from './red_pencil.png'

class About extends Component {
  constructor(props) {
    super(props)
    this.currentTab = this.props.tab
    this.missionImage = React.createRef()
    window.addEventListener('resize', this.setSizes)
  }
  handleSelect(tab) {
    this.currentTab = tab
    // window.history.pushState('state', '', `http://127.0.0.1:3000/${this.currentTab}`)
    window.location.replace(`/${this.currentTab}`)
  }
  componentDidMount() {
    document.title = 'About - Eager Minds Montessori'
  }
  render() {
    return (
      <>
        <Container id={styles.container}>
          <Row className='mb-3' id={styles.topRow}>
            <h1>About</h1>
          </Row>
          <div id={styles.pageNavContainer}>
            <Row className='justify-content-md-center'>
              <Tabs
                defaultActiveKey={this.currentTab}
                className='justify-content-center mb-3'
                onSelect={(tab) => this.handleSelect(tab)}
              >
                {/* <Tab eventKey={'our_story'} title='Our Story'>
                  Eager Minds was founded
                </Tab> */}
                <Tab eventKey={'curriculum'} title='Curriculum'>
                  <Row className='mb-3'>
                    <Col>
                      <div className={styles.card}>
                        <div className={styles.card_image}>
                          <img
                            src={GreenPencils}
                            className={`${styles.pencil_container} mb-3`}
                          ></img>
                        </div>
                        <div className={styles.card_content}>
                          <h2>Language Arts</h2>
                          {/* <DropdownButton id='dropdown-basic-button' title='Dropdown button'>
                            <Dropdown.Item href='#/action-1'>Action</Dropdown.Item>
                            <Dropdown.Item href='#/action-2'>Another action</Dropdown.Item>
                            <Dropdown.Item href='#/action-3'>Something else</Dropdown.Item>
                          </DropdownButton> */}
                          <h4>Pre-Reading</h4>
                          <p>
                            Due to our multi-age classroom design, our youngest students are
                            constantly exposed to the older children in the class who are already
                            reading. We begin to teach reading as soon as that interest is first
                            expressed.
                          </p>
                          <ul>
                            <li>
                              We help the youngest children to develop a highly sophisticated
                              vocabulary and command of the language.
                            </li>
                            <li>
                              Practical life exercises that provide left to right movement and
                              sequences
                            </li>
                            <li>
                              Cylinder blocks and metal insets to prepare hand for handwriting
                            </li>
                            <li>
                              We introduce the children to literature by reading aloud and
                              discussing a wide range of classic stories and poetry.
                            </li>
                            <li>
                              We help our youngest students to recognize the shape and phonetic
                              sounds of the alphabet through the 'sandpaper letters:' a tactile
                              alphabet
                            </li>
                          </ul>
                          <h4>Reading</h4>
                          <ul>
                            <li>
                              The development of the concept that written words are actual thoughts
                              set down on paper. This takes children much longer than most people
                              realize (Age 3-6)
                            </li>
                            <li>
                              Sounding out simple three or four-letter phonetic words (Typically by
                              age 4)
                            </li>
                            <li>
                              Early exercises to practice reading: labeling objects with written
                              name tags, mastering increasingly complex words (Age 3-6)
                            </li>
                            <li>
                              Naming things that interest them, such as dinosaurs, the parts of a
                              flower, geometric shapes, the materials in the classroom, etc. (Age
                              3-6)
                            </li>
                            <li>
                              Learning to recognize verbs: normally exercises in which the child
                              reads a card with a verbal "command" printed out (such as run, sit,
                              walk, etc.) and demonstrates his understanding by acting it out. As
                              the child's reading vocabulary increases, verbal commands involve full
                              sentences and multiple steps: "Place the mat on the table and bring
                              back a red pencil." (Age 3-6)
                            </li>
                            <li>
                              Reading teacher prepared small books on topics that really interest
                              the child, such as in science, geography, nature or history (Age 3-6)
                            </li>
                            <li>
                              Interpretive reading for comprehension at increasing levels of
                              difficulty (Age 3-6)
                            </li>
                          </ul>
                          <h4>Handwriting</h4>
                          <p>
                            Control of the hand in preparation for writing is developed through many
                            exercises, including specially designed tasks in the use of the pencil.
                            Such exercises begin at 18 months and extend over several years so that
                            mastery is thoroughly attained.
                          </p>
                          <ul>
                            <li>
                              The young children practice making letters from the time of their
                              first initial "explosion into writing" (Age 2-6)
                            </li>
                            <li>
                              Moveable Alphabets' made up of easily manipulated wooden letters are
                              used for the early stages of word creation, the analysis of words, and
                              spelling. They facilitate early reading and writing tasks during the
                              period when young children are still not comfortable with their own
                              writing skills. Even before the children are comfortable in their
                              handwriting skills, they spell words, compose sentences and stories,
                              and work on punctuation and capitalization with the moveable alphabets
                              (Age 4-6)
                            </li>
                            <li>
                              At first, by tracing letters into sand, later by writing on special
                              tilted, upright blackboards: unlined, wide-lined, and narrow-lined.
                              Finally by writing on special writing tablets, becoming comfortable
                              with script (Age 2-6)
                            </li>
                          </ul>
                          <h4>Composition</h4>
                          <p>
                            At an early age, before handwriting has been mastered, the children
                            compose sentences, stories, and poetry through oral dictation to adults
                            and with the use of the moveable alphabet. Once handwriting is fairly
                            accomplished, the children begin to develop their composition skills.
                            They continue to develop over the years at increasing levels of
                            sophistication
                          </p>
                          <ul>
                            <li>Preparing written answers to simple questions (Age 4-6)</li>
                            <li>Composing stories to follow a picture series (Age 3-6)</li>
                            <li>
                              Beginning to write stories or poems on given simple themes (Age 4-6)
                            </li>
                            <li>Preparing written descriptions of science experiments (Age 4-6)</li>
                            <li>
                              Creative and expository composition skills continue to develop as the
                              children advance from level to level. Students are typically asked to
                              write on a daily basis, in their journal (Age 4-6)
                            </li>
                          </ul>
                          <h4>Spellings</h4>
                          <p>
                            Children begin to spell using the moveable alphabet to sound out and
                            spell words as they are first learning to read. The sequence of spelling
                            at Eager Minds, as with all language skills, begins much earlier than is
                            traditional.
                          </p>
                          <ul>
                            <li>Learning to sound out and spell simple phonetic words (Age 3-6)</li>
                            <li>
                              Learning to recognize and spell words involving phonograms like, ei/
                              ai (Age 4-6)
                            </li>
                            <li>
                              Developing a first dictionary of words that they can now comprehend
                              (Age 3-6)
                            </li>
                            <li>
                              Learning to recognize and spell the "sight words" of English: words
                              that are non-phonetic and that are not spelled as they sound (Age 4-6)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <div className={styles.card}>
                        <div className={styles.card_image}>
                          <img
                            src={OrangePencils}
                            className={`${styles.pencil_container} mb-3`}
                          ></img>
                        </div>
                        <div className={styles.card_content}>
                          <h2>STEM</h2>
                          <h4>Mathematics</h4>
                          <p>
                            Our students are typically introduced to numbers at age 2, learning the
                            numbers and number symbols one to ten, the red and blue rods, sandpaper
                            numerals, association of number rods and numerals, spindle boxes, cards
                            and counters, counting, sight recognition and concept of odd and even.
                          </p>
                          <ul>
                            <li>
                              Introduction to the decimal system: Units, tens, hundreds, thousands
                              are represented by specially prepared concrete learning materials that
                              show the decimal hierarchy in three dimensional form (Age 3-6)
                            </li>
                            <ul>
                              <li> Units = single beads</li>
                              <li>Tens = a bar of 10 units</li>
                              <li>Hundreds = 10 ten bars fastened together into a square</li>
                              <li>
                                Thousands = a cube ten units long ten units wide and ten units high
                              </li>
                              <li>
                                The children learn to first recognize the quantities, and then to
                                form numbers with the bead or cube materials through 9,999, learn to
                                read and write numerals up to 9,999, and to exchange equivalent
                                quantities of units for tens, tens for hundreds, etc.
                              </li>
                            </ul>
                            <li>
                              Linear Counting: learning the number facts to ten (what numbers make
                              ten, basic addition up to ten); learning the teens (11 = one ten + one
                              unit), counting by tens (34 = three tens + four units) to one hundred
                              (Age 3-6)
                            </li>
                            <li>
                              Development of the concept of the four basic mathematical operations:
                              addition, subtraction, division, and multiplication through work with
                              the Montessori Golden Bead Material. The child builds numbers with the
                              bead material and performs mathematical operations concretely (Age
                              3-6)
                            </li>
                            <li>
                              Development of the concept of "dynamic" addition and subtraction where
                              exchanging and regrouping of numbers is necessary (Age 4-6)
                            </li>
                            <li>
                              Memorization of the basic math facts: adding and subtracting numbers
                              under 10 without the aid of the concrete materials (Age 5-6)
                            </li>
                            <li>
                              Development of further abstract understanding of addition,
                              subtraction, division, and multiplication with large numbers through
                              the Stamp Game (a manipulative system that represents the decimal
                              system as color-keyed "stamps") and the Small and Large Bead Frames
                              (color-coded abacuses) (Age 5-6)
                            </li>
                            <li>
                              Skip counting with the chains of the squares of the numbers from zero
                              to ten: i.e., counting to 25 by 5's, to 36 by 6's, etc. Developing
                              first understanding of the concept of the "square" of a number (Age
                              4-6)
                            </li>
                            <li>
                              Skip counting with the chains of the cubes of the numbers zero to ten:
                              i.e., counting to 1,000 by ones or tens. Developing the first
                              understanding of the concept of a "cube" of a number (Age 4-6)
                            </li>
                            <li>
                              Beginning the "passage to abstraction," the child begins to solve
                              problems with paper and pencil while working with the concrete
                              materials. Eventually, the materials are no longer needed (Age 4-6)
                            </li>
                          </ul>
                          <h4>Geometry</h4>
                          <p>
                            Sensorial exploration of plane and solid figures at the Primary level
                            (Age 3 to 6) - the children learn to recognize the names and basic
                            shapes of plane and solid geometry through manipulation of special
                            wooden geometric insets. They then learn to order them by size or
                            degree.
                          </p>
                          <ul>
                            <li>Stage I: Basic geometric shapes (Age 2)</li>
                            <li>
                              Stage II: More advanced plane geometric shapes-triangles, polygons,
                              various rectangles and irregular forms (Age 3-6)
                            </li>
                            <li>
                              Stage III: Introduction to solid geometric forms and their
                              relationship to plane geometric shapes (Age 2-6)
                            </li>
                            <li>
                              Study of the basic properties and definitions of the geometric shapes.
                              This is essentially as much a reading exercise as mathematics since
                              the definitions are part of the early language materials (Age 4-6)
                            </li>
                            <li>
                              More advanced study of the nomenclature, characteristics, measurement
                              and drawing of the geometric shapes and concepts such as points, line,
                              angle, surface, solid, properties of triangles, circles, etc. (Age
                              4-6)
                            </li>
                          </ul>
                          <h4>Science</h4>
                          <ul>
                            <li>Differentiation between living and non-living things (Age 2-6)</li>
                            <li>
                              Differentiation between animals and plants; basic characteristics (Age
                              2-6)
                            </li>
                            <li>Observation of animals in nature (2 – 6)</li>
                            <li>
                              First puzzles representing the biological parts of flowers, root
                              systems, and trees, along with the anatomical features of common
                              animals. These are first used by very young children and puzzles, then
                              as a means to learn the vocabulary, then are related to photos and/or
                              the "real thing," then traced onto paper, and finally with labels as a
                              reading experience. (Age 2-6)
                            </li>
                            <li>
                              {' '}
                              Nomenclature Cards: Botany: identifying, naming, and labeling the
                              parts of plants, trees, leaves, roots, and flowers (Age 2-6)
                            </li>
                            <li>
                              Zoology: identifying, naming, and labeling the external parts of human
                              beings, insects, fish, birds, and other animals (Age 2-6)
                            </li>
                            <li>
                              Introduction of the families of the animal kingdom, and identification
                              and classification of animals into the broad families. Introduction to
                              the basic characteristics, life-styles, habitats, and means of caring
                              for young of each family in the animal kingdom (Age 2-6)
                            </li>
                            <li>
                              Introduction to ecology: habitat, food chain, adaptation to
                              environment, predator-prey relationships, camouflage, and other body
                              adaptations of animals (Age 3-6)
                            </li>
                            <li>
                              Advanced biology study: the names and functions of different forms of
                              leaves, flowers, seeds, trees, plants, and animals. This usually
                              begins with considerably more fieldwork collecting specimens or
                              observing (Age 4-6)
                            </li>
                            <li>
                              Study the parts of vertebrates: limbs, body coverings, lungs, heart
                              (Age 4-6)
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <div className={styles.card}>
                        <div className={styles.card_image}>
                          <img
                            src={YellowPencils}
                            className={`${styles.pencil_container} mb-3`}
                          ></img>
                        </div>
                        <div className={styles.card_content}>
                          <h2>Social Studies</h2>
                          <h4>Cultural Geography</h4>
                          <p>
                            Countries are studied in many ways at all levels, beginning at about age
                            2. A number of festivals are celebrated every year to focus on specific
                            cultures and to celebrate life together: an example being Chinese New
                            Year, when the entire school studies China, prepares Chinese food,
                            learns Chinese dances, and participates in a special dragon dance.
                            Anything that the children find interesting is used to help them become
                            familiar with the countries of the world: flags, boundaries, food,
                            climate, traditional dress, houses, major cities, children's toys and
                            games, stamps, coins, traditional foods, art, music, and history. This
                            interweaves through the entire curriculum.
                          </p>
                          <ul>
                            <li>
                              Study of the regions, culture, and natural resources of the United
                              States and the world, including geography, climate, flora and fauna,
                              major rivers and lakes, capitals, important cities, mountains, people,
                              regional foods, traditions, etc. (Age 2-6)
                            </li>
                            <li>
                              Art, including two and three dimensional representations and art
                              appreciation
                            </li>
                            <li>Spanish instruction </li>
                            <li>Movement, including dance, creative movement and yoga </li>
                            <li>
                              Theater, including puppetry, dramatic expression and storybook theater
                            </li>
                            <li>Cooking and other activities </li>
                            <li>Holiday Celebrations around the World </li>
                          </ul>
                          <h4>Economic Geography</h4>
                          <ul>
                            <li>Natural Resources of the Earth (Age 3-6)</li>
                            <li> Production: how humankind uses natural resources (Age 4-6)</li>
                            <li>
                              History and the needs all people share. The basic needs of man are
                              food, shelter, clothing, defense, transportation, culture, law, love,
                              and adornment (Age 3-6)
                            </li>
                            <li>
                              The concept of time and historical time is developed through many
                              activities and repeated at deeper complexity:
                            </li>
                            <ul>
                              <li>Telling time on the clock</li>
                              <li>Time-lines of the child's life</li>
                              <li>
                                Time-lines showing the activities of a day, week, month, year,
                                family trees
                              </li>
                              <li>Time-line of the Earth's history</li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mb-3'>
                    <Col>
                      <div className={styles.card}>
                        <div className={styles.card_image}>
                          <img
                            src={BlackPencils}
                            className={`${styles.pencil_container} mb-3`}
                          ></img>
                        </div>
                        <div className={styles.card_content}>
                          <h2>Life Skills</h2>
                          <p>
                            One of the first goals of EM is to develop in the very young child a
                            strong and realistic sense of independence and self-reliance. Along with
                            love and a stable environment, this is the child's greatest need. This
                            area of the curriculum focuses on developing skills that allow the child
                            to effectively control and deal with the social and physical environment
                            in which he lives. There is a growing pride in being able to "do it for
                            myself." Practical life begins as soon as the young child enters the
                            school and continues throughout the curriculum to more and more advanced
                            tasks appropriate to the oldest students.
                          </p>
                          <h4>Early Skills</h4>
                          <ul>
                            <li>Dressing oneself</li>
                            <li>Pouring liquids without spilling</li>
                            <li>Carrying objects and liquids without dropping</li>
                            <li>Walking without knocking into furniture or people</li>
                            <li>Using knives and scissors with good control</li>
                            <li>Using simple tools</li>
                            <li>
                              Putting materials away on the shelves where they belong when finished
                            </li>
                            <li>Working carefully and neatly</li>
                            <li>
                              Dusting, polishing and washing just about anything: floors, tables,
                              silver
                            </li>
                            <li>Sweeping and vacuuming floors and rugs</li>
                            <li>Flower arranging</li>
                            <li>Caring for plants and pets</li>
                            <li>Table setting, serving yourself, table manners</li>
                            <li>Folding cloth: napkins, towels, etc.</li>
                            <li>Simple use of needle and thread</li>
                            <li>
                              Using household tools: tweezers, tongs, eye-droppers, locks, scissors,
                              knives
                            </li>
                            <li>Work with increasingly precise eye-hand coordination</li>
                            <li>Simple cooking and food preparation</li>
                            <li>Learning home address and phone number</li>
                            <li>Dish washing</li>
                            <li>Weaving, bead stringing, etc.</li>
                          </ul>
                          <h4>Advanced Skills</h4>
                          <ul>
                            <li>Caring for pets</li>
                            <li>Sewing</li>
                            <li>Gardening</li>
                            <li>Interior decorating</li>
                            <li>Cooking meals</li>
                            <li>Working with hand tools</li>
                            <li>Making simple repairs</li>
                            <li>Laundering clothes </li>
                            <li>Survival skills</li>
                          </ul>
                          <h4>Sensory Training</h4>
                          <p>
                            These are exercises in perception, observation, fine differentiation,
                            and classification that play a major role in helping our children to
                            develop their sense of logic and concentration.
                          </p>
                          <ul>
                            <li>Determination of length, width, height and volume</li>
                            <li>Differentiation in multiple dimensions</li>
                            <li>Differentiation among color tones</li>
                            <li>
                              Differentiation among geometric shapes for shape and relative size
                            </li>
                            <li>Differentiation among solid geometric shapes by sight and touch</li>
                            <li>Solving of complex abstract puzzles in three dimensions</li>
                            <li>Differentiation of intensity and nature of sounds</li>
                            <li>Differentiation among musical tones</li>
                            <li>Determination of texture by touch</li>
                            <li>Determination of weight by touch</li>
                            <li>Determination of temperature by touch</li>
                            <li>Determination of scents</li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey={'mission'} title='Mission'>
                  <Row>
                    <div className='col-lg-12 order-2 order-lg-1 mb-3'>
                      <div className={`${styles.card} mb-3`}>
                        <div
                          className={styles.card_image}
                          style={{ backgroundColor: 'var(--soft-blue)', width: '10%' }}
                        >
                          <img
                            src={BluePencil}
                            className={`${styles.pencil} mb-3`}
                            style={{ maxHeight: '70%' }}
                          ></img>
                        </div>
                        <div className={styles.card_content} style={{ width: '100%' }}>
                          <Row>
                            <h2>Our Mission</h2>
                            <div
                              id={styles.gettingWorkCol}
                              className='col-md-6 col-lg-4 order-md-2 mb-3'
                            >
                              <img id={styles.gettingWorkImageDesktop} src={GettingWork}></img>
                            </div>
                            <div className='col-md-6 col-lg-8 order-md-1 mb-3'>
                              <p>
                                The staff and community of Eager Minds Montessori are committed to
                                creating a rich and satisfying preschool experience for both you and
                                your child. We are loyal to the Montessori method of education,
                                which helps the child to learn to discipline, to perfect, and to
                                progress at their own pace. We care about academic success and the
                                enthusiasm for knowledge and learning. We care about manners,
                                courtesy, human kindness and service to others. We support respect
                                for elders, parents and teachers, and above all, respect for
                                oneself.
                              </p>

                              <div id={styles.gettingWorkCol}>
                                <img id={styles.gettingWorkImageMobile} src={GettingWork}></img>
                              </div>

                              <p>
                                We recognize that the first six years are critical to the
                                development of one's life. We believe that the experiences children
                                have both at home and at school in the first six years are the most
                                important of their lifetime. It is through these experiences, their
                                concept of the world and their place in it are set in stone. Eager
                                Minds Montessori is neither a babysitting service nor a play-school
                                that prepares a child for traditional school. Rather, it is a unique
                                cycle of learning designed to provide stimulation through an
                                enriched environment. By pursuing their individual interest in a
                                Montessori classroom, children gain enthusiasm for learning.
                              </p>
                              <br></br>
                              <p>
                                <div id={styles.missionQuoteSection} className='mb-3 mt-3'>
                                  <h5>
                                    "Eager Minds provides a unique cycle of learning designed to
                                    provide stimulation through an enriched environment."
                                  </h5>
                                </div>
                                <br></br>
                                At Eager Minds, we emphasize the child’s growth and development as a
                                total person. We allow children to take the lead in their learning
                                process based on their unique needs. Our curriculum is sequenced to
                                expose children to all subject areas, firing imagination and
                                providing lifelong skills. Our teachers gently direct the child's
                                interest from the concrete to the abstract. As a result, many
                                children don't even realize they are performing at a level "beyond
                                their years".
                              </p>
                            </div>
                          </Row>
                        </div>
                      </div>
                      <div className={`${styles.card} mb-3`}>
                        <div
                          className={styles.card_image}
                          style={{ backgroundColor: 'var(--soft-blue)', width: '10%' }}
                        >
                          <img
                            src={GreenPencil}
                            className={`${styles.pencil} mb-3`}
                            style={{ maxHeight: '70%' }}
                          ></img>
                        </div>
                        <div className={styles.card_content} style={{ width: '100%' }}>
                          <h2>Interdisciplinary Approach </h2>
                          <p>
                            The course of study is organized as an inclined spiral plane of
                            integrated studies, rather than a model in which the curriculum is
                            compartmentalized into separate subjects, with given topics considered
                            only once at a given grade level. Our lessons are introduced simply and
                            concretely in the early years and are reintroduced several times during
                            the following years at increasing degrees of complexity. Our course of
                            study is an integrated thematic approach that ties the separate
                            disciplines of the curriculum together. This integrated approach is one
                            of EM’s great strengths. As an example, when our students study about
                            U.K in the continent of Europe (geography), they learn about:
                          </p>
                          <ul>
                            <li>Language arts: Shakespeare through an introduction to his plays</li>
                            <li>Creative writing: Write stories with picture captions</li>
                            <li>
                              Vocabulary: New words like Muffin man juggler, theatre, actor etc
                            </li>
                            <li>History: Types of people and community in Renaissance England</li>
                            <li>
                              Math: Counting maze (from London Bridge to Globe Theatre), English
                              money
                            </li>
                            <li>Drama: Using props and costumes, recreate clothing then and now</li>
                            <li>
                              Art: Create a poster describing London Bridge and round Globe Theatre
                            </li>
                            <li>Music: Folk music of Ireland and Scotland</li>
                            <li>
                              Famous architecture: London bridge, Buckingham palace, Globe Theatre
                            </li>
                            <li>Culture: British tea ritual</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className='col-lg-6 order-1 order-lg-2' style={{ textAlign: 'center' }}> */}
                    {/* <img src={MissionImage} id={styles.missionImage} className='mb-3'></img> */}
                    {/* </div> */}
                  </Row>

                  {/* <Row>
                    <div className='col-lg-6 order-2 order-lg-1'>
                      <div className={styles.card}>
                        <div className={styles.card_image}></div>
                        <div className={styles.card_content}>
                          <h2>Our Mission</h2>
                          <p>
                            The staff and community of Eager Minds Montessori are committed to
                            creating a rich and satisfying preschool experience for both you and
                            your child. We are loyal to the Montessori method of education, which
                            helps the child to learn to discipline, to perfect, and to progress at
                            their own pace. We care about academic success and the enthusiasm for
                            knowledge and learning. We care about manners, courtesy, human kindness
                            and service to others. We support respect for elders, parents and
                            teachers, and above all, respect for oneself.
                          </p>
                        </div>
                      </div>
                      <p>
                        The staff and community of Eager Minds Montessori are committed to creating
                        a rich and satisfying preschool experience for both you and your child. We
                        are loyal to the Montessori method of education, which helps the child to
                        learn to discipline, to perfect, and to progress at their own pace. We care
                        about academic success and the enthusiasm for knowledge and learning. We
                        care about manners, courtesy, human kindness and service to others. We
                        support respect for elders, parents and teachers, and above all, respect for
                        oneself.
                        <br></br>
                        <br></br>
                        <br></br>
                        We recognize that the first six years are critical to the development of
                        one's life. We believe that the experiences children have both at home and
                        at school in the first six years are the most important of their lifetime.
                        It is through these experiences, their concept of the world and their place
                        in it are set in stone. Eager Minds Montessori is neither a babysitting
                        service nor a play-school that prepares a child for traditional school.
                        Rather, it is a unique cycle of learning designed to provide stimulation
                        through an enriched environment. By pursuing their individual interest in a
                        Montessori classroom, children gain enthusiasm for learning.
                        <br></br>
                        <br></br>
                        <div id={styles.missionQuoteSection} className='mb-3 mt-3'>
                          <h5>
                            "Eager Minds provides a unique cycle of learning designed to provide
                            stimulation through an enriched environment."
                          </h5>
                        </div>
                        <br></br>
                        At Eager Minds, we emphasize the child’s growth and development as a total
                        person. We allow children to take the lead in their learning process based
                        on their unique needs. Our curriculum is sequenced to expose children to all
                        subject areas, firing imagination and providing lifelong skills. Our
                        teachers gently direct the child's interest from the concrete to the
                        abstract. As a result, many children don't even realize they are performing
                        at a level "beyond their years".
                      </p>
                    </div>
                    <div className='col-lg-6 order-1 order-lg-2' style={{ textAlign: 'center' }}>
                      <img src={MissionImage} id={styles.missionImage} className='mb-3'></img>
                    </div>
                  </Row> */}
                </Tab>
                {/* <Tab eventKey={'faq'} title='FAQ'>
                  FAQ
                </Tab> */}
              </Tabs>
            </Row>
          </div>
        </Container>
      </>
    )
  }
}

// const About = () => (
//   <>
//     <Container id={styles.container}>
//       <Row className='mb-3' id={styles.topRow}>
//         <h1>About</h1>
//       </Row>
//       <div id={styles.pageNavContainer}>
//         <Row className='justify-content-md-center'>
//           {/* <Col id={styles.pageNavLink}>Mission</Col>
//           <Col id={styles.pageNavLink}>Curriculum</Col>
//           <Col id={styles.pageNavLink}>FAQ</Col>
//           <hr id={styles.pageNavUnderline}></hr> */}
//           <Nav activeKey={1} variant='tabs' className='justify-content-center'>
//             <Nav.Item>
//               <Nav.Link eventKey={1}>Mission</Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey={2}>Curriculum</Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey={3}>FAQ</Nav.Link>
//             </Nav.Item>
//           </Nav>
//         </Row>
//       </div>
//     </Container>
//   </>
// )

export default About
