import './global_styles/App.css'
import 'bootstrap/dist/css/bootstrap.css'

import Navigation from './components/Navigation'
import Home from './pages/Home'
import About from './pages/About'
import Enroll from './pages/Enroll'
import Contact from './pages/Contact'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import NotFound from './pages/NotFound'

const NavigationWithRouter = withRouter(Navigation)

function App() {
  return (
    <BrowserRouter>
      <NavigationWithRouter />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/mission' component={() => <About tab='mission' />} />
        <Route exact path='/curriculum' component={() => <About tab='curriculum' />} />
        {/* <Route exact path='/our_story' component={() => <About tab='our_story' />} /> */}
        {/* <Route exact path='/faq' component={() => <About tab='faq' />} /> */}
        <Route exact path='/enroll' component={Enroll} />
        <Route exact path='/contact' component={Contact} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
