import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

const NotFound = () => (
  <Container>
    <Row style={{ height: '80vh' }}>
      <div class='col my-auto' style={{ textAlign: 'center' }}>
        <h1>404 - Page not found</h1>
        <Link to='/'>Go Home</Link>
      </div>
    </Row>
  </Container>
)

export default NotFound
