import { Container, Row, Col } from 'react-bootstrap'
import { Form, Card } from 'react-bootstrap'
import { Alert, Button, CloseButton } from 'react-bootstrap'
import SecondaryButton from '../../components/SecondaryButton'

import styles from './Contact.module.css'
import { Component } from 'react'
import axios from 'axios'
import qs from 'qs'

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      type: 'A parent wishing to enroll your child',
      subject: '',
      message: '',
      formSubmitted: false,
      submitionSuccess: false,
    }
  }

  handleSubmitSuccess(event) {
    event.target.reset()

    this.setState({
      formSubmitted: true,
      submitionSuccess: true,
    })

    setTimeout(() => {
      this.setState({
        formSubmitted: false,
      })
    }, 5000)
  }

  handleSubmitError(event) {
    this.setState({
      formSubmitted: true,
      submitionSuccess: false,
    })
    console.log(event)

    setTimeout(() => {
      this.setState({
        formSubmitted: false,
      })
    }, 5000)
  }

  sendContactForm(event) {
    event.preventDefault()
    const data = {
      name: this.state.name,
      email: this.state.email,
      type: this.state.type,
      subject: this.state.subject,
      message: this.state.message,
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: qs.stringify(data),
      url: '/backend_info/submit_contact_form',
    }
    this.setState({ name: '' })
    this.setState({ email: '' })
    this.setState({ type: 'A parent wishing to enroll your child' })
    this.setState({ subject: '' })
    this.setState({ message: '' })
    axios(options)
      .then(this.handleSubmitSuccess(event))
      .catch((error) => {
        this.handleSubmitError(error)
      })
  }

  componentDidMount() {
    document.title = 'Contact - Eager Minds Montessori'
  }

  render() {
    var statusMessage = ''
    if (this.state.formSubmitted) {
      if (this.state.submitionSuccess) {
        statusMessage = (
          <Alert id={styles.statusMessage} variant='success'>
            Message sent successfully!
            <CloseButton
              onClick={() => this.setState({ formSubmitted: false })}
              variant='outline-success'
            ></CloseButton>
          </Alert>
        )
      } else {
        statusMessage = (
          <Alert id={styles.statusMessage} variant='danger'>
            Message could not be sent
            <CloseButton
              onClick={() => this.setState({ formSubmitted: false })}
              variant='outline-danger'
            ></CloseButton>
          </Alert>
        )
      }
    }

    return (
      <Container>
        {statusMessage}
        <Row className='mb-3' id={styles.topRow}>
          <h1>Contact Us</h1>
        </Row>
        {/* <Row>
          <Col>
            <Form id={styles.contactForm} onSubmit={(event) => this.sendContactForm(event)}>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Name'
                      value={this.state.name}
                      onChange={(event) => this.setState({ name: event.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='name@example.com'
                      value={this.state.email}
                      onChange={(event) => this.setState({ email: event.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>You are:</Form.Label>
                    <Form.Select
                      value={this.state.type}
                      onChange={(event) =>
                        this.setState({
                          type: event.target.options[event.target.selectedIndex].text,
                        })
                      }
                    >
                      <option value='enroll'>A parent wishing to enroll your child</option>
                      <option value='info'>A parent looking for more information</option>
                      <option value='job'>A job seeker</option>
                      <option value='other'>Other</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3'>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Subject'
                      value={this.state.subject}
                      onChange={(event) => this.setState({ subject: event.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className='mb-3' controlId='textArea'>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{ maxHeight: '100vh' }}
                      rows={3}
                      value={this.state.message}
                      onChange={(event) => this.setState({ message: event.target.value })}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div style={{ textAlign: 'center' }} className='mb-3'>
                <SecondaryButton type={'submit'} text={'Send'}></SecondaryButton>
              </div>
            </Form>
          </Col>
        </Row> */}
        <Row id={styles.bottomRow}>
          <Col lg={6}>
            <Card id={styles.infoCard} className='mb-3'>
              <Card.Body>
                <Card.Title className='mb-2'>Contact Information</Card.Title>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th>Phone #</th>
                      <td>
                        <a href='tel:2487589727'>(248) 758-9727</a>
                      </td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>
                        <a href='mailto:eagerkids@gmail.com'>eagerkids@gmail.com</a>
                      </td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>
                        <div>2100 Bloomfield Woods Ct</div>
                        <div>West Bloomfield, MI 48323</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            <Card id={styles.infoCard} className='mb-3'>
              <Card.Body>
                <Card.Title className='mb-2'>Business Hours</Card.Title>
                <table style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <th>Monday</th>
                      <td>7am - 6pm</td>
                    </tr>
                    <tr>
                      <th>Tuesday</th>
                      <td>7am - 6pm</td>
                    </tr>
                    <tr>
                      <th>Wednesday</th>
                      <td>7am - 6pm</td>
                    </tr>
                    <tr>
                      <th>Thursday</th>
                      <td>7am - 6pm</td>
                    </tr>
                    <tr>
                      <th>Friday</th>
                      <td>7am - 6pm</td>
                    </tr>
                    <tr>
                      <th>Saturday</th>
                      <td>Closed</td>
                    </tr>
                    <tr>
                      <th>Sunday</th>
                      <td>Closed</td>
                    </tr>
                  </tbody>
                </table>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card id={styles.infoCard} className='mb-3'>
              <Card.Body>
                <Card.Title className='mb-2'>Map</Card.Title>
                <Card.Text>
                  <iframe
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2937.1530363008546!2d-83.32755918437624!3d42.594496479171845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8824bf14f69eac57%3A0x20bb809524c41e17!2sEagerMinds%20Montessori%20Preschool%2F%20Daycare!5e0!3m2!1sen!2sus!4v1629169577575!5m2!1sen!2sus'
                    width='100%'
                    height='400px'
                    style={{ border: '0' }}
                    loading='lazy'
                    allowFullScreen
                  ></iframe>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Contact
