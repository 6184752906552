import { Button } from 'react-bootstrap'
import styles from './PrimaryButton.module.css'
import React from 'react'

const PrimaryButton = (props) => (
  <Button id={styles.button} type={props.type} href={props.href} onClick={props.onClick}>
    {props.text}
  </Button>
)

export default PrimaryButton
