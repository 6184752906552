// import Combined from './main_image.png'
import Collage from './collage.png'
import HomeDetail from '../../components/HomeDetailBackground'
import Cleaning from './cleaning.png'
import Coloring from './coloring.png'
import Drawing from './drawing.png'
import Counting from './counting.png'
import Teaching from './teaching.png'
import styles from './Home.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import PrimaryButton from '../../components/PrimaryButton'

import React, { Component } from 'react'

class Home extends Component {
  render() {
    return (
      <>
        <Container>
          <Row id={styles.topRow}>
            <Col md={6} style={{ maxWidth: '600px' }} id={styles.collageCol}>
              <img src={Collage} id={styles.collage} alt='' />
            </Col>
            <Col md={6} id={styles.textCol}>
              <div>
                <h1 id={styles.header}>Eager Minds Montessori,</h1>
              </div>
              <div>
                <h1 id={styles.header}>Where Children Blossom</h1>
              </div>
              <p>
                We are a montessori preschool in West Bloomfield, Michigan with the mission to
                provide a nurturing environment and quality education.
              </p>
              <div id={styles.buttons}>
                {/* <Button href='/about' id={styles.homeButton}>
                About
              </Button>
              <Button href='/apply' id={styles.homeButton}>
                Apply
              </Button>
              <Button href='/contact' id={styles.homeButton}>
                Contact
              </Button> */}
                <PrimaryButton href={'/curriculum'} text={'About'}></PrimaryButton>
                <PrimaryButton href={'/contact'} text={'Enroll'}></PrimaryButton>
                <PrimaryButton href={'/contact'} text={'Contact'}></PrimaryButton>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <Container fluid={true} className='p-0'>
          <Row id={styles.bottomRow}>
            <Col id={styles.detailCol}>
              <HomeDetail />
            </Col>
          </Row>
        </Container> */}
      </>
    )
  }
}

export default Home
